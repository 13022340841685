import React from 'react'
import { formatTime, strToNum } from './util'
import vektoriousLogo from '../images/vektorious-logo.png'
import demo from '../videos/demo.mp4'

class WarpScoreBoard extends React.Component {

    renderClock( options ) {

        let value     = 0
        let isRunning = false

        if ( options.clock ) {

            const clockState = options.clock.button ? options.clock.button.substring( 2 ) : 'ST'

            isRunning = clockState !== 'ST'
            value     = options.clock.value ? strToNum( options.clock.value ) : 0

            if ( ! options.clock.isUpcount ) {

                value = options.clock.limit - value

            }

            if ( isRunning ) {

                const offset = Math.ceil( ( this.props.timestamp.getTime() - options.clock.timestamp.getTime() ) / 1000 )

                if ( options.clock.isUpcount ) {

                    value += offset

                    if ( value > options.clock.limit ) {

                        value = options.clock.limit

                    }

                } else {

                    value -= offset

                    if ( value < 0 ) {

                        value = 0

                    }

                }

            }

        }

        return (

            <div className={ 'clock ' + options.className + ( isRunning ? ' running' : '' ) } data-value={ value }>{ options.format ? formatTime( value ) : value }</div>

        )

    }

    render() {

        let awayTeamPossessionTime = 0
        let homeTeamPossessionTime = 0
        let totalPossessionTime = 0
        let awayTeamPossessionPercentage = 0
        let homeTeamPossessionPercentage = 0

        if ( this.props.game ) {

            awayTeamPossessionTime = Math.ceil( this.props.game.awayTeamPossessionTime / 1000 )
             homeTeamPossessionTime = Math.ceil( this.props.game.homeTeamPossessionTime / 1000 )

            if ( this.props.game.homeTeamShotClock && this.props.game.homeTeamShotClock.button && this.props.game.homeTeamShotClock.button.substring( 2 ) !== 'ST' ) {

                homeTeamPossessionTime += Math.ceil( ( this.props.timestamp.getTime() - this.props.game.homeTeamShotClock.timestamp.getTime() ) / 1000 )

            } else if ( this.props.game.awayTeamShotClock && this.props.game.awayTeamShotClock.button && this.props.game.awayTeamShotClock.button.substring( 2 ) !== 'ST' ) {

                awayTeamPossessionTime += Math.ceil( ( this.props.timestamp.getTime() - this.props.game.awayTeamShotClock.timestamp.getTime() ) / 1000 )

            }

            totalPossessionTime          = awayTeamPossessionTime + homeTeamPossessionTime
            awayTeamPossessionPercentage = totalPossessionTime ? Math.round( awayTeamPossessionTime / totalPossessionTime * 100 ) : 0
            homeTeamPossessionPercentage = totalPossessionTime ? Math.round( homeTeamPossessionTime / totalPossessionTime * 100 ) : 0

        }

        return (

            <div className="warpscoreboard">

                <div className={ 'video' + ( this.props.displayVideo ? ' visible' : '' ) }>

                    <video src={ demo } autoPlay={ true } loop={ true }></video>

                </div>

                <header className={ this.props.displayHeader ? ' visible' : '' }>

                    { this.props.game &&

                        <>

                            <div className="team home">{ this.props.game.homeTeamShortName }</div>
                            <div className="team away">{ this.props.game.awayTeamShortName }</div>

                            <div className="score home">{ this.props.game.homeTeamScore }</div>
                            <div className="score away">{ this.props.game.awayTeamScore }</div>

                            { this.renderClock( { className: 'main', clock: this.props.game.mainClock, format: true } ) }
                            { this.renderClock( { className: 'home', clock: this.props.game.homeTeamShotClock       } ) }
                            { this.renderClock( { className: 'away', clock: this.props.game.awayTeamShotClock       } ) }

                        </>

                    }

                    <img src={ vektoriousLogo } alt="" className="vektorious-logo" />

                </header>

                { this.props.game &&

                    <>

                        <div className={ 'insert left' + ( this.props.displaySog ? ' visible' : '' ) }>

                            <div className="team">

                                <div className="name">{ this.props.game.homeTeamShortName }</div>
                                <div className="value">{ this.props.game.homeTeamSog }</div>

                            </div>

                            <div className="team">

                                <div className="name">{ this.props.game.awayTeamShortName }</div>
                                <div className="value">{ this.props.game.awayTeamSog }</div>

                            </div>

                            <div className="label">SHOTS</div>

                        </div>

                        <div className={ 'insert right' + ( this.props.displayPossessionTime ? ' visible' : '' ) }>

                            <div className="team">

                                <div className="name">{ this.props.game.homeTeamShortName }</div>
                                <div className="value small">{ homeTeamPossessionPercentage }%</div>
                                <div className="value">{ formatTime( homeTeamPossessionTime ) }</div>

                            </div>

                            <div className="team">

                                <div className="name">{ this.props.game.awayTeamShortName }</div>
                                <div className="value small">{ awayTeamPossessionPercentage }%</div>
                                <div className="value">{ formatTime( awayTeamPossessionTime ) }</div>

                            </div>

                            <div className="label">POSS.</div>

                        </div>

                    </>

                }

            </div>

        )

    }


}

export default WarpScoreBoard