import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { formatTime, strToNum } from './util'

class Game extends React.Component {

    #skeletonColor
    #skeletonColorRed
    #skeletonHightlightColor
    #skeletonHightlightColorRed

    constructor( props ) {

        super( props )

        this.#skeletonColor              = '#202020'
        this.#skeletonColorRed           = '#ed1d24'
        this.#skeletonHightlightColor    = '#444'
        this.#skeletonHightlightColorRed = '#f66'

        this.state = {

            loading: true,

        }

    }

    componentDidMount() {

        setTimeout( () => this.setState( { loading: false } ), 2500 )

    }

    renderClock( options ) {

        let value     = 0
        let isRunning = false

        if ( options.clock ) {

            const clockState = options.clock.button ? options.clock.button.substring( 2 ) : 'ST'

            isRunning = clockState !== 'ST'
            value     = options.clock.value ? strToNum( options.clock.value ) : 0

            if ( ! options.clock.isUpcount ) {

                value = options.clock.limit - value

            }

            if ( isRunning ) {

                const offset = Math.ceil( ( this.props.timestamp.getTime() - options.clock.timestamp.getTime() ) / 1000 )

                if ( options.clock.isUpcount ) {

                    value += offset

                    if ( value > options.clock.limit ) {

                        value = options.clock.limit

                    }

                } else {

                    value -= offset

                    if ( value < 0 ) {

                        value = 0

                    }

                }

            }

        }

        return (

            <div>

                <div className={ options.classNames + ( isRunning ? ' running' : '' ) } data-value={ value }>{ this.state.loading ? this.renderSkeleton( { color: this.#skeletonColorRed, highlightColor: this.#skeletonHightlightColorRed } ) : ( options.format ? formatTime( value ) : value ) }</div>

            </div>

        )

    }

    renderSkeleton( options ) {

        options = { ...{

            circle:         false,
            color:          this.#skeletonColor,
            count:          1,
            height:         null,
            highlightColor: this.#skeletonHightlightColor,

        }, ...options }

        return <SkeletonTheme color={ options.color } highlightColor={ options.highlightColor }><Skeleton circle={ options.circle } count={ options.count } height={ options.height || ( options.circle ? 60 : null ) } width={ options.circle ? 60 : null } /></SkeletonTheme>

    }

    renderTeamLogo( team ) {

        return (

            <div className={ 'team ' + team + ( this.props.game.mainSponsor && ! this.state.loading ? ' flipv' : '' ) }>

                {

                    this.state.loading ?

                        this.renderSkeleton( { circle: true } )

                    :

                    this.props.game.mainSponsor ?

                        <div className="card">

                            <div className="back"><a href={ this.props.game.mainSponsor.url } target="_blank" rel="noopener noreferrer" title={ this.props.game.mainSponsor.title }><img src={ this.props.game.mainSponsor.logoUrl } alt={ this.props.game.mainSponsor.title } /></a></div>

                            <div className="front"><img src={ this.props.game[ team + 'TeamLogoUrl' ] } alt={ this.props.game[ team + 'TeamShortName' ] } title={ this.props.game[ team + 'TeamShortName' ] } /></div>

                        </div>

                    :

                        <img src={ this.props.game[ team + 'TeamLogoUrl' ] } alt={ this.props.game[ team + 'TeamShortName' ] } title={ this.props.game[ team + 'TeamShortName' ] } />

                }

            </div>

        )

    }

    render() {

        let awayTeamPossessionTime = Math.ceil( this.props.game.awayTeamPossessionTime / 1000 )
        let homeTeamPossessionTime = Math.ceil( this.props.game.homeTeamPossessionTime / 1000 )

        if ( this.props.game.homeTeamShotClock && this.props.game.homeTeamShotClock.button && this.props.game.homeTeamShotClock.button.substring( 2 ) !== 'ST' ) {

            homeTeamPossessionTime += Math.ceil( ( this.props.timestamp.getTime() - this.props.game.homeTeamShotClock.timestamp.getTime() ) / 1000 )

        } else if ( this.props.game.awayTeamShotClock && this.props.game.awayTeamShotClock.button && this.props.game.awayTeamShotClock.button.substring( 2 ) !== 'ST' ) {

            awayTeamPossessionTime += Math.ceil( ( this.props.timestamp.getTime() - this.props.game.awayTeamShotClock.timestamp.getTime() ) / 1000 )

        }

        const totalPossessionTime          = awayTeamPossessionTime + homeTeamPossessionTime
        const awayTeamPossessionPercentage = totalPossessionTime ? Math.round( awayTeamPossessionTime / totalPossessionTime * 100 ) : 0
        const homeTeamPossessionPercentage = totalPossessionTime ? Math.round( homeTeamPossessionTime / totalPossessionTime * 100 ) : 0
        // const scores                       = [ ...this.props.game.scores ].reverse()

        return (

            <div className="game">

                <div className="game-name">{ this.state.loading ? this.renderSkeleton() : this.props.game.gameName }</div>

                <div className="row">

                    { this.renderTeamLogo( 'home' ) }

                    { this.renderClock( { classNames: 'game-time', clock: this.props.game.mainClock, format: true } ) }

                    { this.renderTeamLogo( 'away' ) }

                </div>

                <div className="row">

                    { this.renderClock( { classNames: 'value nullable', clock: this.props.game.homeTeamShotClock } ) }
                    <div><div className="label">Shot<br />Clock</div></div>
                    { this.renderClock( { classNames: 'value nullable', clock: this.props.game.awayTeamShotClock } ) }

                </div>

                <div className="row highlight">

                    <div><div className={ 'value' + ( this.props.game.animHomeScore ? ' anim' : '' ) }>{ this.state.loading ? this.renderSkeleton() : this.props.game.homeTeamScore }</div></div>
                    <div><div className="label">Score</div></div>
                    <div><div className={ 'value' + ( this.props.game.animAwayScore ? ' anim' : '' ) }>{ this.state.loading ? this.renderSkeleton() : this.props.game.awayTeamScore }</div></div>

                </div>

                <div className="row">

                    <div><div className="value">{ this.state.loading ? this.renderSkeleton() : this.props.game.homeTeamSog }</div></div>
                    <div><div className="label">Shots</div></div>
                    <div><div className="value">{ this.state.loading ? this.renderSkeleton() : this.props.game.awayTeamSog }</div></div>

                </div>

                <div className="row">

                    <div><div className="value small">{ this.state.loading ? this.renderSkeleton( { count: 2 } ) : <>{ formatTime( homeTeamPossessionTime ) }<br />{ homeTeamPossessionPercentage } %</> }</div></div>
                    <div><div className="label">Poss.</div></div>
                    <div><div className="value small">{ this.state.loading ? this.renderSkeleton( { count: 2 } ) : <>{ formatTime( awayTeamPossessionTime ) }<br />{ awayTeamPossessionPercentage } %</> }</div></div>

                </div>

                {/* <div className="scores">

                    {

                        this.state.loading ?

                            this.renderSkeleton( { count: 5, height: 25 } )

                        :

                            scores.map( ( e, i ) =>

                                <div key={ 'score-' + i } className="score">

                                    <div>{ e.period }</div>
                                    <div>{ formatTime( e.gameTime ) }</div>
                                    <div>{ e.newScore }</div>
                                    <div>{ e.scorerTeam }</div>

                                </div>

                            )

                    }

                </div> */}

                { this.props.game.sponsors &&

                <div className="sponsors">

                    {

                        this.props.game.sponsors.map( ( sponsor, i ) =>

                            <div className={ 'sponsor' + ( this.props.game.animSponsors ? ' anim' : '' ) } key={ i }>

                                <a href={ sponsor.url } target="_blank" rel="noopener noreferrer" title={ sponsor.title }><img src={ sponsor.logoUrl } alt={ sponsor.title } /></a>

                            </div>

                        )

                    }

                </div>

                }

            </div>

         )

    }

}

export default Game