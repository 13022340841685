import { Puff } from 'react-loading-icons'
import React from 'react'
import Game from './Game'
import hockeydataLogo from '../images/hockeydata-logo.png'
import isiLiveLogo from '../images/isilive-logo.png'
import vektoriousLogo from '../images/vektorious-logo.png'
import packageJson from '../../package.json'

class WarpScore extends React.Component {

    render() {

        return (

            <>

                <div className="branding"><div>VEKTORIOUS<br />WARPSCORE</div></div>

                <header className="warpscore-header">

                    <div></div>
                    <div></div>

                    <a href="./"><img src={ vektoriousLogo } alt="" /></a>

                </header>

                <div className="content">

                    {

                        this.props.loading ?

                            <div className="loading"><Puff /></div>

                        :

                            <div className="games">

                                {

                                    this.props.games.length ?

                                        this.props.games.map( ( e, i ) => <Game key={ 'game-' + i } game={ e } timestamp={ this.props.timestamp } /> )

                                    :

                                        <div className="notice">No games live. Please check back later.</div>

                                }

                            </div>

                    }

                </div>

                <footer>

                    <div className="wrapper">

                        <a href="https://www.isilive.ca/" target="_blank" rel="noreferrer"><img src={ isiLiveLogo } alt="iSi Live" /></a>

                        <a href="http://www.hockeydata.net/" target="_blank" rel="noreferrer"><img src={ hockeydataLogo } alt="hockeydata" /></a>

                        <p className="copyright">v{packageJson.version} © { new Date().getFullYear() } Vektorious. All Rights Reserved.</p>

                    </div>

                </footer>

                { this.props.error && <div className="error">Couldn't load data.</div> }

            </>

        )

    }


}

export default WarpScore