import React from 'react'
import WarpScore from './WarpScore'
import WarpScoreBoard from './WarpScoreBoard'

class App extends React.Component {

    #fetchGamesTimeout
    #fetchTimingsTimeout
    #fetchTimingsTimer
    #originalDocumentTitle
    #updateTimesTimeout
    #updateTimesTimer
    #updateDocumentTitleTimeout
    #updateDocumentTitleTimer

    constructor( props ) {

        super( props )

        this.state = {

            error:                 null,
            flashDocumentTitle:    false,
            games:                 [],
            loading:               true,
            displayHeader:         false,
            displayPossessionTime: false,
            displayScoreboard:     false,
            displaySog:            false,
            displayVideo:          false,
            timestamp:             null,

        }

        this.#originalDocumentTitle = document.title

        this.#fetchGamesTimeout           = 1000 * 2
        this.#fetchTimingsTimeout         = 1000 * 1
        this.#updateTimesTimeout          = 1000 * 1
        this.#updateDocumentTitleTimeout  = 1000 * 1

        this.handleDocumentVisibilityChange = this.handleDocumentVisibilityChange.bind( this )
        this.handleKeyUp                    = this.handleKeyUp.bind( this )

    }

    componentDidMount() {

        document.addEventListener( 'visibilitychange', this.handleDocumentVisibilityChange )
        document.addEventListener( 'keyup', this.handleKeyUp )

        setTimeout( () => this.fetchGames(),  this.#fetchGamesTimeout  )

        this.#updateDocumentTitleTimer = setInterval( () => this.updateDocumentTitle(), this.#updateDocumentTitleTimeout )

    }

    componentWillUnmount() {

        if ( this.#fetchTimingsTimer        ) { clearInterval( this.#fetchTimingsTimer        ) }
        if ( this.#updateTimesTimer         ) { clearInterval( this.#updateTimesTimer         ) }
        if ( this.#updateDocumentTitleTimer ) { clearInterval( this.#updateDocumentTitleTimer ) }

        document.removeEventListener( 'visibilitychange', this.handleDocumentVisibilityChange )
        document.removeEventListener( 'keyup', this.handleKeyUp )

    }

    fetchGames() {

        // TODO load games via webservice

        const games = [

            {

                awayTeamId:                 23948,
                awayTeamLogoUrl:            'assets/images/wora.png',
                awayTeamPossessionTime:     0,
                awayTeamScore:              null,
                awayTeamShortName:          "WORA",
                awayTeamShotClock:          null,
                awayTeamSog:                0,
                gameName:                   "U19AA",
                gameTime:                   1200,
                homeTeamId:                 23875,
                homeTeamLogoUrl:            'assets/images/ice.png',
                homeTeamPossessionTime:     0,
                homeTeamScore:              null,
                homeTeamShortName:          "ICE",
                homeTeamShotClock:          null,
                homeTeamSog:                0,
                id:                         "abc-123",
                mainClock:                  null,
                // mainSponsor:                {

                //     logoUrl: 'assets/images/boston-pizza-rect.png',
                //     title:   'Boston Pizza',
                //     url:     'https://bostonpizza.com/',

                // },
                scores:                     [],
                sponsors:                   [

                    { logoUrl: 'assets/images/boston-pizza.png',     title: 'Boston Pizza',     url: 'https://bostonpizza.com/'     },
                    { logoUrl: 'assets/images/ringette-ontario.png', title: 'Ringette Ontario', url: 'https://ringetteontario.com/' },

                ]

            }

        ]

        this.setState( { games, loading: false } )

        this.fetchTimings()

        if ( ! this.#updateTimesTimer ) {

            this.#updateTimesTimer = setInterval( () => this.updateTimes(), this.#updateTimesTimeout )

        }

    }

    fetchTimings() {

        fetch( 'https://warpscoreapp.hockeydata.net/Home/GetTimings' )
            .then( response => response.json() )
            .then( e => {

                const timestamp          = new Date( e.timestamp )
                  let flashDocumentTitle = this.state.flashDocumentTitle

                const games = this.state.games.map( game => {

                    // TODO return value from webservice must be array or object with values for each game

                    const state = {}

                    state.animAwayScore = game.awayTeamScore !== null && game.awayTeamScore !== e.score.away && e.score.away !== 0
                    state.animHomeScore = game.homeTeamScore !== null && game.homeTeamScore !== e.score.home && e.score.home !== 0
                    state.animSponsors  = state.animAwayScore || state.animHomeScore

                    state.awayTeamPossessionTime = e.possession.away
                    state.awayTeamScore          = e.score.away
                    state.awayTeamShotClock      = e.shotClkAway
                    state.awayTeamSog            = e.sog.away
                    state.homeTeamPossessionTime = e.possession.home
                    state.homeTeamScore          = e.score.home
                    state.homeTeamShotClock      = e.shotClkHome
                    state.homeTeamSog            = e.sog.home
                    state.mainClock              = e.mainClock

                    state.awayTeamShotClock.timestamp = new Date( state.awayTeamShotClock.timestamp )
                    state.homeTeamShotClock.timestamp = new Date( state.homeTeamShotClock.timestamp )
                    state.mainClock.timestamp         = new Date( state.mainClock.timestamp         )

                    if ( state.animSponsors ) {

                        flashDocumentTitle = true

                    }

                    return { ...game, ...state }

                } )

                this.setState( { flashDocumentTitle, games, timestamp } )

                if ( ! this.#fetchTimingsTimer ) {

                    this.#fetchTimingsTimer = setInterval( () => this.fetchTimings(), this.#fetchTimingsTimeout )

                }

            } )
            .catch( error => this.setState( { error } ) )

    }

    handleDocumentVisibilityChange() {

        if ( this.state.flashDocumentTitle && ! document.hidden ) {

            this.setState( { flashDocumentTitle: false } )

        }

    }

    handleKeyUp( e ) {

        if ( e.which === 72 ) { // h

            this.toggleState( 'displayHeader' )

        } else if ( e.which === 77 ) { // m

            this.toggleState( 'displayScoreboard' )

        } else if ( e.which === 80 ) { // p

            this.toggleState( 'displayPossessionTime' )

        } else if ( e.which === 83 ) { // s

            this.toggleState( 'displaySog' )

        } else if ( e.which === 86 ) { // v

            this.toggleState( 'displayVideo' )

        }

    }

    toggleState( e ) {

        this.setState( { [ e ]: ! this.state[ e ] } )

    }

    updateTimes() {

        const timestamp = this.state.timestamp ? new Date( this.state.timestamp.getTime() + this.#updateTimesTimeout ) : null

        this.setState( { timestamp } )

    }

    updateDocumentTitle() {

        if ( document.hidden && this.state.flashDocumentTitle ) {

            if ( document.title === this.#originalDocumentTitle ) {

                document.title = 'GOAL! ' + this.#originalDocumentTitle

            } else {

                document.title = this.#originalDocumentTitle

            }

        } else if ( document.title !== this.#originalDocumentTitle ) {

            document.title = this.#originalDocumentTitle

        }

    }

    render() {

        return (

            this.state.displayScoreboard ?

                <WarpScoreBoard
                    displayHeader={ this.state.displayHeader }
                    displaySog={ this.state.displaySog }
                    displayPossessionTime={ this.state.displayPossessionTime }
                    displayVideo={ this.state.displayVideo }
                    game={ this.state.games[ 0 ] }
                    timestamp={ this.state.timestamp }
                />

            :

                <WarpScore
                    error={this.state.error}
                    games={ this.state.games }
                    loading={ this.state.loading }
                    timestamp={ this.state.timestamp }
                />

         )

    }

}

export default App